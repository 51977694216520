<template>
	<div>
		<div class="ser-form">
			<Form ref="form" :model="search" inline>
				<FormItem>
					<Input v-model="search.name" placeholder="分类名称" />
				</FormItem>
				<FormItem>
					<Button type="primary" icon="md-search" @click="init()">搜索</Button>
				</FormItem>
				<FormItem>
					<Button type="primary" icon="md-add" @click="edit_data()">新增分类</Button>
				</FormItem>
			</Form>
		</div>
		<div class="table-list">
			<Table :loading="loading" :columns="columns" :data="data">
				<template slot-scope="{row}" slot="status">
					<Tag :color="row.status ? 'success' : 'error'">{{ row.status ? '显示' : '隐藏' }}</Tag>
				</template>
				<template slot-scope="{row}" slot="operate">
					<Button @click="edit_data(row)">编辑</Button>
				</template>
			</Table>
		</div>
		<div class="ser-page">
			<Page class-name="page-r" :total="search.total" :current="search.page" :page-size="search.pageSize" :page-size-opts="[15,30,50]" @on-change="changePage" @on-page-size-change="changePageSize" show-total show-sizer></Page>
		</div>
		<Modal v-model="editModal.show" :title="(editModal.data.id ? '编辑' : '添加')+'分类'">
			<Form :model="editModal.data" :label-width="120" label-colon>
				<FormItem label="分类名称">
					<Input v-model="editModal.data.name"></Input>
				</FormItem>
				<FormItem label="是否显示">
					<RadioGroup v-model="editModal.data.status">
						<Radio :label="1">显示</Radio>
						<Radio :label="0">隐藏</Radio>
					</RadioGroup>
				</FormItem>
			</Form>
			<template slot="footer">
				<Button @click="editModal.show = false" style="margin-right: 10px;">取消</Button>
				<Button @click="confirm_edit_data()" type="primary">确认</Button>
			</template>
		</Modal>
	</div>
</template>

<script>
	var add_data = {
		id: null,
		name: '',
		status: 1
	};
	export default {
		name: "DeviceCategory",
		data() {
			return {
				loading: false,
				openModel: false,
				search: {
					name: '',
					page: 1,
					pageSize: 15,
					total: 0
				},
				editModal: {
					show: false,
					data: JSON.parse(JSON.stringify(add_data))
				},
				columns: [
					// { type: 'selection', width: 60 },
					{ title: 'ID', key: 'id', minWidth: 80 },
					// {title: '图标', key: 'icon', minWidth: 70},
					{ title: '分类名称', key: 'name', minWidth: 100 },
					{ title: '状态', slot: 'status', minWidth: 80 },
					{ title: '添加时间', key: 'create_time', minWidth: 160 },
					// {title: '排序', key: 'sort', minWidth: 70},
					{ title: '操作', slot: 'operate', minWidth: 170 }
				],
				data: []
			}
		},
		created() {
			this.init()
		},
		methods: {
			init() {
				var _this = this;
				this.loading = true;
				this.requestApi('/adm/get_category_list', this.search).then((res) => {
					if (res.status) {
						_this.data = res.data.data;
						_this.search.total = res.data.total;
						_this.loading = false;
					}
				})
			},
			// 新增/编辑 设备分类
			edit_data(data) {
				if (data) {
					for(let index in this.editModal.data){
						this.editModal.data[index] = data[index];
					}
				} else {
					this.editModal.data = JSON.parse(JSON.stringify(add_data));
				}
				this.editModal.show = true;
			},
			// 确认 添加/编辑 设备分类
			confirm_edit_data(){
				var _this = this;
				this.requestApi('/adm/edit_device_category', { data: this.editModal.data }).then((res) => {
					if (res.status) {
						_this.editModal.show = false;
						_this.alertSucc('保存成功');
						_this.init();
					} else {
						_this.alertErr(res.msg);
					}
				})
			},
			changePage(page){
				this.search.page = page;
				this.init();
			},
			changePageSize(size){
				this.search.page = 1;
				this.search.pageSize = size;
				this.init();
			},
		}
	}
</script>

<style scoped>

</style>
